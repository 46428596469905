export function hamburger() {
  //ハンバーガーボタン
  const Trigger = document.querySelector('.js-hamburgerTrigger');
  //ハンバーガーメニュー
  const menu = document.querySelector('.js-hamburgerMenu');
  Trigger.addEventListener('click', () => {
    if ( Trigger.classList.contains('is-active') == true ) {
      Trigger.classList.remove('is-active');
      Trigger.classList.add('is-close');
      menu.classList.remove('is-view');
    } else {
      Trigger.classList.add('is-active');
      Trigger.classList.remove('is-close');
      menu.classList.add('is-view');
    }
  });
}

export function smoothscroll() {
  $(function(){
    $('.js-smoothscroll').click(function(){
      let speed = 750;
      let href= $(this).attr("href");
      let target = $(href == "#" || href == "" ? 'html' : href);
      let position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });
}
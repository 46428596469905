import { throttle } from 'lodash';

export function scrollAction() {
  window.addEventListener(
    'scroll',
    _.throttle(
      e => {
        // ここに処理
        //追従バナー
        const bnr = document.querySelector('.c-bnr_tel');
        const pagetop = document.querySelector('.l-footer_pagetop');
        const footer = document.querySelector('.l-footer_contact');
        if( footer.classList.contains('is-animated') == true ){
          //何か処理を書く
          bnr.classList.add('is-static');
          pagetop.classList.add('is-transform');
        } else {
          bnr.classList.remove('is-static');
          pagetop.classList.remove('is-transform');
        }
        return false;
      },
      50,
      { trailing: true, leading: true }
    )
  );
}
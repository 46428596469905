export function location() {
  $(function(){
    //パス取得
    var pageURL = window.location.pathname,
    pageURLArr = pageURL.split('/'), //パスを分割して配列化する
    pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得

    //ヘッダーメニュー取得
    var header_menu = $('.l-header_nav--list');
    header_menu.find('.' + pageURLArrCategory).addClass('current');
  });
}

import ScrollMagic from 'scrollmagic';

export function scrollmagic() {

  //見出しアニメーション
  let controllerHeading = new ScrollMagic.Controller();

  let scrollheading = document.querySelectorAll('.js-headingAnimation');

  for (let i = 0; i < scrollheading.length; i++) {
    let tag = scrollheading[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 250,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controllerHeading);
  }

    //フェードイン
    let controllerfadeIn = new ScrollMagic.Controller();

    let scrollfadeIn = document.querySelectorAll('.js-scrollfadeIn');

    for (let i = 0; i < scrollfadeIn.length; i++) {
      let tag = scrollfadeIn[i];

      new ScrollMagic.Scene({
        triggerElement: tag,
        triggerHook: 'onEnter',
        offset: 100,
      })
        .setClassToggle(tag, 'is-animated')
        .addTo(controllerfadeIn);
    }

    //Leave処理
    let controllerLeave = new ScrollMagic.Controller();

    let scrollLeave = document.querySelectorAll('.js-scrollAnimation03');

    for (let i = 0; i < scrollLeave.length; i++) {
      let tag = scrollLeave[i];

      new ScrollMagic.Scene({
        triggerElement: tag,
        triggerHook: 'onLeave',
        offset: 0,
      })
        .setClassToggle(tag, 'is-animated')
        .addTo(controllerLeave);
    }

    //Enter処理
    let controllerEnter = new ScrollMagic.Controller();

    let scrollEnter = document.querySelectorAll('.js-scrollAnimation');

    for (let i = 0; i < scrollEnter.length; i++) {
      let tag = scrollEnter[i];

      new ScrollMagic.Scene({
        triggerElement: tag,
        triggerHook: 'onEnter',
        offset: 0,
      })
        .setClassToggle(tag, 'is-animated')
        .addTo(controllerEnter);
    }
  

  //Enter処理
  // let controllerEnter = new ScrollMagic.Controller();

  // let scrollEnter = document.querySelectorAll('.js-scrollAnimation');

  // for (let i = 0; i < scrollEnter.length; i++) {
  //   let tag = scrollEnter[i];

  //   new ScrollMagic.Scene({
  //     triggerElement: tag,
  //     triggerHook: 'onEnter',
  //     offset: 0,
  //   })
  //     .setClassToggle(tag, 'is-animated')
  //     .addTo(controllerEnter);
  // }

  //Center処理
  // let controllerCenter = new ScrollMagic.Controller();

  // let scrollCenter = document.querySelectorAll('.js-scrollAnimation02');

  // for (let i = 0; i < scrollCenter.length; i++) {
  //   let tag = scrollCenter[i];

  //   new ScrollMagic.Scene({
  //     triggerElement: tag,
  //     triggerHook: 'onCenter',
  //     offset: 0,
  //   })
  //     .setClassToggle(tag, 'is-animated')
  //     .addTo(controllerCenter);
  // }

  //Leave処理
  // let controllerLeave = new ScrollMagic.Controller();

  // let scrollLeave = document.querySelectorAll('.js-scrollAnimation03');

  // for (let i = 0; i < scrollLeave.length; i++) {
  //   let tag = scrollLeave[i];

  //   new ScrollMagic.Scene({
  //     triggerElement: tag,
  //     triggerHook: 'onLeave',
  //     offset: 0,
  //   })
  //     .setClassToggle(tag, 'is-animated')
  //     .addTo(controllerLeave);
  // }
}

import { scrollmagic } from './modules/scrollmagic';
import { scrollAction } from './modules/scrollAction';
import { hamburger } from './modules/hamburger';
import { bnr } from './modules/bnr';
import { smoothscroll } from './modules/smoothscroll';
import { pagetop } from './modules/pagetop';
import { location } from './modules/location';

window.addEventListener('load', () => {
  //crollに応じてアニメーションしたい時
  　scrollmagic();
  
    //crollによる処理を間引きしたい時
  　scrollAction();
});

hamburger();
bnr();
smoothscroll();
pagetop();
location();
